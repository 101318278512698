<template>
  <nav class="header">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-md-11 col-lg-8 col-xl-7 col-xxl-6">
          <div class="header-container d-flex align-items-center justify-content-between">
            <router-link class="header-logo d-flex align-items-center text-decoration-none"
                         to="/">
              <img alt="" src="../../assets/logo.svg">
              <span>
                MathGuru App
              </span>
            </router-link>
            <a :href="$store.state.appConfig.appStoreUrl"
               class="header-download btn btn-primary site-button">
              Get it
            </a>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'Header',
  data() {
    return {
      openMobile: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.header {
  background: #201D1D;
  padding: 16px 0;

  &-logo {
    color: white;
    transition-duration: .3s;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 17.3185px;
    line-height: 20px;

    &:hover {
      color: #bbbbbb;
    }

    img {
      width: 32px;
      height: 32px;
      margin-right: 6.5px;
    }
  }
}
</style>
