<template>
  <nav class="footer">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-md-11 col-lg-8 col-xl-7 col-xxl-6">
          <div class="footer-interactive d-flex align-items-center justify-content-between">
            <router-link to="/" class="footer__logo">
              <img src="../../assets/logo.svg" alt="">
              <span>
                MathGuru App
              </span>
            </router-link>
            <a :href="$store.state.appConfig.appStoreUrl"
               class="footer__download btn btn-primary site-button">
              Get it
            </a>
          </div>
        </div>
      </div>
      <div class="row justify-content-center pt-3 pb-4">
        <div class="col-12 col-md-3 text-center">
          <router-link to="terms"
                       class="btn btn-link text-decoration-none text-white-50">
            Terms Of Use
          </router-link>
        </div>
        <div class="col-12 col-md-3 text-center">
          <router-link to="pp"
                       class="btn btn-link text-decoration-none text-white-50 mt-2 mt-md-0">
            Privacy Policy
          </router-link>
        </div>
        <div class="col-12 col-md-3 text-center">
          <router-link to="faq"
                       class="btn btn-link text-decoration-none text-white-50 mt-2 mt-md-0">
            FAQ
          </router-link>
        </div>
        <div class="col-12 col-md-3 text-center">
          <a href="https://www.facebook.com/MathGuru-Homework-Solver-101379862480035"
             class="btn btn-link text-decoration-none text-white-50 mt-2 mt-md-0">
            Contact us
          </a>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'Footer',
};
</script>

<style scoped lang="scss">

.footer {
  background: #201D1D;
  padding: 32px 0;

  &__logo {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: white;
    transition-duration: .3s;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 17.3185px;
    line-height: 20px;

    &:hover {
      color: #bbbbbb;
    }

    img {
      width: 32px;
      height: 32px;
      margin-right: 6.5px;
    }
  }
}
</style>
