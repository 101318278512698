import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    appConfig: {
      appStoreUrl: `https://web2app.fx2.io/checkout?event_id=${document.querySelector('#eventId')
        .innerText
        .trim()}&landing=${document.querySelector('#landing_hash')
        .innerText
        .trim()}&appstore_event_id=true`,
    },
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  },
});
