<template>
  <div class="slider">
    <Swiper ref="mySwiper" :options="swiperOption">
      <SwiperSlide v-for="comment in comments" :key="comment.id">
        <div class="slide d-flex flex-column align-items-center">
          <img src="../assets/Format_quote.svg"
               class="slide-quotes"
               alt="">
          <p class="slide-text text-center">
            {{ comment.text }}
          </p>
          <div class="slide-commentator text-center">
            <img :src="comment.avatarUrl"
                 class="slide-commentator__img"
                 alt="">
            <p class="slide-commentator__name">
              {{ comment.name }}
            </p>
            <p class="slide-commentator__during">
              {{ comment.during }}
            </p>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/swiper.min.css';

export default {
  name: 'Slider',
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    comments: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      offsetLeft: 0,
      swiperOption: {
        slidesPerView: 1,
        spaceBetween: 24,
        centeredSlides: true,
      },
      activeSlide: 0,
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
};
</script>

<style lang="scss" scoped>
.slider {
  .slide {
    padding: 48px 40px;
    border: 1px solid #E7E5EA;
    box-sizing: border-box;
    border-radius: 16px;

    &-quotes {
      margin-bottom: 24px;
    }

    &-text {
      font-weight: bold;
      font-size: 18px;
      line-height: 155%;
      letter-spacing: -0.02em;
      color: #363636;
      margin-bottom: 24px;
    }

    &-commentator {
      &__img {
        border-radius: 50%;
        margin-bottom: 16px;
      }

      &__name {
        font-weight: bold;
        font-size: 16px;
        line-height: 26px;
        letter-spacing: -0.355556px;
        color: #12022F;
        margin-bottom: 4px;
      }

      &__during {
        font-size: 14px;
        line-height: 24px;
        letter-spacing: -0.3px;
        color: #594D6D;
      }
    }
  }
}
</style>
