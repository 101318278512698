<template>
  <div class="home">
    <div class="hero">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-11 col-lg-8 col-xl-7 col-xxl-6">
            <h1 class="hero__title text-white text-center">
              Become real math guru!
            </h1>
            <p class="hero__text text-white text-center">
              Don’t waste your time – choose MathGuru and get answers to your math problems
              instantly.
            </p>
            <div class="hero__img"></div>
            <a :href="$store.state.appConfig.appStoreUrl"
               class="button btn btn-primary w-100 site-button">
              Get it for FREE
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="steps">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-11 col-lg-8 col-xl-7 col-xxl-6">
            <div class="steps-container">
              <ul class="steps-list">
                <li class="steps-list__item">
                  Take a
                  <span class="steps-list__item_highlighted">
                    photo
                  </span>
                </li>
                <li class="steps-list__item">
                  Get the
                  <span class="steps-list__item_highlighted">
                    answer
                  </span>
                </li>
                <li class="steps-list__item">
                  Step-by-step
                  <span class="steps-list__item_highlighted">
                    solution
                  </span>
                  <span class="steps-list__item_or">
                    or
                  </span>
                </li>
                <li class="steps-list__item">
                  Use
                  <span class="steps-list__item_highlighted">
                    Expert chat
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
<!--    <div class="stats">-->
<!--      <div class="container">-->
<!--        <div class="row justify-content-center">-->
<!--          <div class="col-12 col-md-11 col-lg-8 col-xl-7 col-xxl-6">-->
<!--            <div class="stats-container">-->
<!--              <div class="stats-row d-flex">-->
<!--                <div class="stats-block flex-grow-1 text-center">-->
<!--                  <p class="stats-block__title">-->
<!--                    App downloads-->
<!--                  </p>-->
<!--                  <p class="stats-block__num">-->
<!--                    100K-->
<!--                  </p>-->
<!--                  <p class="stats-block__description">-->
<!--                    monthly-->
<!--                  </p>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="stats-row d-flex">-->
<!--                <div class="stats-block flex-grow-1 text-center">-->
<!--                  <p class="stats-block__title">-->
<!--                    Feedback-->
<!--                  </p>-->
<!--                  <p class="stats-block__num">-->
<!--                    1.4K-->
<!--                  </p>-->
<!--                  <p class="stats-block__description">-->
<!--                    reviews-->
<!--                  </p>-->
<!--                </div>-->
<!--                <div class="stats-block flex-grow-1 text-center">-->
<!--                  <p class="stats-block__title">-->
<!--                    Stars-->
<!--                  </p>-->
<!--                  <p class="stats-block__num">-->
<!--                    4.6-->
<!--                  </p>-->
<!--                  <p class="stats-block__description">-->
<!--                    in AppStore-->
<!--                  </p>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <div class="description">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-11 col-lg-8 col-xl-7 col-xxl-6">
            <div class="description-container d-flex flex-column align-items-center">
              <div class="description-title d-flex align-items-center">
                <img alt=""
                     class="description-title__img"
                     src="../assets/logo.svg">
                <h2 class="description-title__text">
                  MathGuru
                </h2>
              </div>
              <p class="description-text text-center">
                The app helps and teaches you how to do your maths homework fast and correctly.
                MathGuru also fits for you if you are not strong enough in math solving or need
                some extra help with it sometimes.
              </p>
              <p class="description-quotes text-center">
                “The best app that saves time and gives results”
              </p>
              <a :href="$store.state.appConfig.appStoreUrl"
                 class="button btn btn-primary w-100 site-button">
                Get it for FREE
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="functions">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-11 col-lg-8 col-xl-7 col-xxl-6">
            <div class="functions-container">
              <div class="functions-block">
                <h3 class="functions-block__title text-white text-center">
                  Upload any photo to solve the Math problem.
                </h3>
                <p class="functions-block__text text-white text-center">
                  All you have to do is to take a photo of the math problem or fill the equation in
                  and press the Enter button. MathGuru can even recognize handwritten problems as
                  well as printed ones with step-by-step solution.
                </p>
                <div class="functions-block__img functions-block__img_1"></div>
              </div>
              <div class="functions-delimiter"></div>
              <div class="functions-block">
                <h3 class="functions-block__title text-white text-center">
                  Get step-by-step solution.
                </h3>
                <p class="functions-block__text text-white text-center">
                  MathGuru provides both straight answers and the corresponding step-by-step
                  solutions.
                </p>
                <div class="functions-block__img functions-block__img_2"></div>
              </div>
              <div class="functions-list">
                <h3 class="functions-list-title text-white text-center">
                  Cover
                  <span class="functions-list-title_highlighted">
                    all levels
                  </span>
                  of Math
                </h3>
                <ul>
                  <li class="functions-list__item text-white">
                    Basic Math/Pre-Algebra
                  </li>
                  <li class="functions-list__item text-white">
                    Algebra
                  </li>
                  <li class="functions-list__item text-white">
                    Trigonometry/ Precalculus
                  </li>
                  <li class="functions-list__item text-white">
                    Calculus
                  </li>
                  <li class="functions-list__item text-white">
                    Statistics
                  </li>
                </ul>
              </div>
              <a :href="$store.state.appConfig.appStoreUrl"
                 class="button btn btn-primary w-100 site-button">
                Get it for FREE
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="comments">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-11 col-lg-8 col-xl-7 col-xxl-6">
            <div class="comments-container">
              <h2 class="comments-title text-center">
                What do People say about us?
              </h2>
              <Slider :comments="comments"
                      class="comments-slider"
              ></Slider>
              <div class="comments-for">
                <h3 class="comments-for__title">
                  MathGuru
                  <span class="comments-for__title_highlighted">
                    for Students
                  </span>
                </h3>
                <p class="comments-for__text">
                  Prepare better for tests, finish your homework faster, and make getting high
                  grades easier.
                </p>
              </div>
              <div class="comments-photo">
                <div class="comments-photo__bg"></div>
                <img alt=""
                     class="comments-photo__img"
                     src="../assets/man.png">
              </div>
              <div class="comments-for">
                <h3 class="comments-for__title">
                  MathGuru
                  <span class="comments-for__title_highlighted">
                    for Parents
                  </span>
                </h3>
                <p class="comments-for__text">
                  Become a math expert for your children and help them to understand Math better and
                  faster.
                </p>
              </div>
              <div class="comments-photo">
                <div class="comments-photo__bg"></div>
                <img alt=""
                     class="comments-photo__img"
                     src="../assets/woman.png">
              </div>
              <a :href="$store.state.appConfig.appStoreUrl"
                 class="button btn btn-primary w-100 site-button">
                Get it for FREE
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Slider from '@/components/Slider.vue';

export default {
  name: 'Home',
  components: {
    Slider,
  },
  data() {
    return {
      comments: [
        {
          id: 0,
          name: 'Stan Stue',
          avatarUrl: 'https://picsum.photos/50/50',
          text: 'I’m not that good at math. Actually, I’m not good at it at all 😅 That is why each time my son asks for help with his math homework I literally freeze up. MathGuru helps a lot though. It gives speedy answers and helps me explain the solutions to my kid. I’m thinking about changing my 1-month subscription to a 6-month one.',
          during: 'Our user during 2 years',
        },
        {
          id: 1,
          name: 'Max Wick',
          avatarUrl: 'https://picsum.photos/48/48',
          text: 'I needed to check my homework and MathGuru did it so quickly, in a flesh! I just sent them snaps with tasks and that’s it! What’s more, they have an option to show step-by-step solutions and explanations to your tasks. Just WOW😍😍😍 Of course, I got an A+. I will definitely use it again and again!',
          during: 'Our user during 3 years',
        },
        {
          id: 2,
          name: 'Luka Rigo',
          avatarUrl: 'https://picsum.photos/52/52',
          text: 'MathGuru is such a great tool! It helped me out so many times with my homeworks and even math tests. I don’t need to waste a bunch of time struggling with math anymore. Awesome feeling! I definitely recommend it to all college students, as it’s capable of solving even the most difficult equations. Tested! A nice way to ease your life!',
          during: 'Our user during 3 years',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.hero {
  background: #201D1D;
  padding: 24px 0;

  &__title {
    font-weight: bold;
    font-size: 36px;
    line-height: 46px;
    letter-spacing: -1.6px;
    margin-bottom: 24px;
  }

  &__text {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: -0.36px;
    padding: 0 40px;
    margin-bottom: 40px;
  }

  &__img {
    height: 420px;
    background-image: url('../assets/app_prev_1.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    margin-bottom: 40px;
  }
}

.steps {
  padding: 32px 0;

  &-container {
    position: relative;

    &:before {
      content: '';
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
      position: absolute;
      width: 3px;
      height: 95%;
      background: #2787f5;
      border-radius: 8px;
    }
  }

  &-list {
    list-style: none;
    padding-left: 10px;
    margin: 0;

    &__item {
      font-style: normal;
      font-weight: bold;
      font-size: 28px;
      line-height: 40px;
      letter-spacing: -0.36px;
      color: #363636;

      &_highlighted {
        color: #2787f5;
        font-style: italic;
      }

      &_or {
        font-style: italic;
        font-weight: bold;
        font-size: 19px;
        line-height: 40px;
        text-decoration-line: underline;
      }
    }
  }
}

.stats {
  background: #EDEDFF;

  &-container {
    padding-bottom: 40px;
  }

  &-row {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 0);
      width: 25%;
      height: 2px;
      background: #71a6ec;
    }
  }

  &-block {
    padding: 32px 0 40px;

    &__title {
      font-weight: bold;
      font-size: 21px;
      line-height: 26px;
      letter-spacing: -0.36px;
      color: #000000;
    }

    &__num {
      font-weight: bold;
      font-size: 60px;
      line-height: 1;
      letter-spacing: -1.23231px;
      color: #2787f5;
      margin: 0;
    }

    &__description {
      font-style: italic;
      font-weight: 500;
      font-size: 16px;
      line-height: 26px;
      letter-spacing: -0.36px;
      color: #747474;
      margin: 0;
    }
  }
}

.description {
  &-container {
    padding: 40px 0;
  }

  &-title {
    margin-bottom: 24px;

    &__img {
      margin-right: 12px;
    }

    &__text {
      font-size: 17.3185px;
      line-height: 20px;
      letter-spacing: -0.002em;
      color: #363636;
      margin: 0;
    }
  }

  &-text {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.3px;
    color: #747474;
    margin-bottom: 32px;
  }

  &-quotes {
    padding: 24px;
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.4px;
    color: #363636;
    background: #EDEDFF;
    border-radius: 24px;
    margin-bottom: 40px;
  }
}

.functions {
  background: #201D1D;

  &-container {
    padding: 40px 0;
  }

  &-block {
    padding: 0 8px 56px;

    &__title {
      font-weight: bold;
      font-size: 28px;
      line-height: 40px;
      letter-spacing: -0.36px;
      margin-bottom: 24px;
    }

    &__text {
      font-size: 16px;
      line-height: 28px;
      letter-spacing: -0.36px;
      margin-bottom: 40px;
    }

    &__img {
      height: 488px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;

      &_1 {
        background-image: url('../assets/app_prev_2.png');
      }

      &_2 {
        background-image: url('../assets/app_prev_3.png');
      }
    }
  }

  &-delimiter {
    width: 50%;
    height: 2px;
    background: white;
    margin: 0 auto 56px;
  }

  &-list {
    margin-bottom: 40px;

    &-title {
      font-weight: bold;
      font-size: 28px;
      line-height: 40px;
      letter-spacing: -0.36px;
      margin-bottom: 24px;

      &_highlighted {
        color: #5E5CE6;
        font-style: italic;
      }
    }

    ul {
      list-style: none;
      padding-left: 3rem;
      margin-bottom: 0;
    }

    &__item {
      position: relative;
      font-size: 21px;
      line-height: 53px;
      letter-spacing: -0.481091px;
      margin-bottom: 16px;

      &:before {
        content: '';
        background-image: url("../assets/A+.svg");
        width: 24px;
        height: 24px;
        background-position: center;
        position: absolute;
        right: calc(100% + 16px);
        top: 50%;
        transform: translate(0, -50%);
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.comments {

  &-container {
    padding: 40px 0 104px;
  }

  &-title {
    font-weight: bold;
    font-size: 28px;
    line-height: 40px;
    margin-bottom: 40px;
  }

  &-slider {
    margin-bottom: 40px;
    overflow: hidden;
  }

  &-for {
    margin-bottom: 56px;
    padding-left: 16px;
    position: relative;

    &:before {
      content: '';
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
      position: absolute;
      width: 3px;
      height: 95%;
      background: #5E5CE6;
      border-radius: 8px;
    }

    &__title {
      font-weight: bold;
      font-size: 28px;
      line-height: 40px;
      letter-spacing: -0.36px;
      color: #201D1D;
      margin-bottom: 24px;

      &_highlighted {
        color: #5E5CE6;
        font-style: italic;
      }
    }

    &__text {
      font-weight: 500;
      font-size: 18px;
      line-height: 31px;
      letter-spacing: -0.36px;
      margin-bottom: 0;
    }
  }

  &-photo {
    padding-right: 40px;
    padding-bottom: 40px;
    position: relative;
    margin-bottom: 40px;
    display: flex;
    justify-content: center;

    &__bg {
      position: absolute;
      z-index: -1;
      width: 306px;
      height: 280px;
      transform: translate(40px, 40px);
      background: #605D60;
      border-radius: 32px;
    }

    &__img {
      z-index: 2;
    }
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (min--moz-device-pixel-ratio: 2),
only screen and (-o-min-device-pixel-ratio: 2/1) {
  .hero__img {
    background-image: url('../assets/app_prev_1_2x.png');
  }
  .functions-block__img_1 {
    background-image: url('../assets/app_prev_2_2x.png');
  }
  .functions-block__img_2 {
    background-image: url('../assets/app_prev_3_2x.png');
  }
}
</style>
