import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min';
import './assets/css/main.scss';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

let fbp;
function pageViewEmit() {
  const formData = new FormData();
  formData.append('event_id', document.querySelector('#eventId').innerText.trim());
  formData.append('event_type', 'pageview');
  fetch('https://web2app.fx2.io/event/store',
    {
      method: 'POST',
      body: formData,
    });
}

function initFbp() {
  fbp = /_fbp=(fb\.1\.\d+\.\d+)/.exec(window.document.cookie);
  if (!fbp) {
    setTimeout(initFbp, 1000);
  } else if (fbp[1]) {
    const formData = new FormData();
    formData.append('event_id', document.querySelector('#eventId').innerText.trim());
    formData.append('event_type', 'fbp');
    formData.append('fbp', fbp[1]);
    fetch('https://web2app.fx2.io/event/store',
      {
        method: 'POST',
        body: formData,
      });
  }
}

document.title = 'MathGuru';

pageViewEmit();
initFbp();
